@import 'assets/scss/global-styles.scss';

.default-view {
  background-color: $primaryLight;
  padding: 64px 128px;

  &:has(.dashboard) {
    padding: 0 32px 64px;

    @media screen and (max-width: $bp-md) {
      padding: 32px 24px;
    }
  }


  @media screen and (max-width: $bp-md) {
    padding: 32px 24px;
  }

  h1 {
    margin-top: 0;
  }

  .head {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    column-gap: 32px;
    box-sizing: border-box;

    h3 {
      margin: 0;
      white-space: nowrap;
    }

    .searchbar {
      width: 100%;
      height: 100%;
      flex-grow: 1;
      position: relative;

      input {
        display: block;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 16px 16px 16px calc(16px + 24px + 8px);
        cursor: text;
      }

      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.4;
        left: 16px;
        pointer-events: none;
      }
    }
  }
}
