// color variants
@import './theme-vars.module.scss';

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

$bp-sm: 480px;
$bp-md: 1024px;

/*
    Import here all the custom css/scss
    you want to be global in the app 
    This can be :
        - Default theming
        - Fonts
        - Animations
        - Etc...
*/

body {
    margin: 0;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $accentMain;
    background-color: $primaryLight;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

    color: $grey300;
    margin-bottom: 10px;
}

@font-face {
    font-family: 'Roboto Regular';
    src: url('../fonts/Roboto-Regular.woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $accentMain;
}

p {
    color: $grey600;
    margin-bottom: 10px;
}

a {
    color: inherit;
}

button,
.button {
    background-color: $accentMain;
    color: $primaryLight;
    font-size: 16px;
    border: 1px solid $accentMain;
    padding: $smallSpacing $mediumSpacing;
    border-radius: 6px;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &:hover {
        background-color: $primaryLight;
        color: $accentMain;
    }

    &.alt {
        background-color: $ctaMain;
        border-color: $ctaMain;
        color: $primaryLight;

        &:hover {
            background-color: darken($ctaMain, 8%);
            border-color: darken($ctaMain, 8%);
            color: $primaryLight;
        }
    }

    &.sec {
        background-color: $primaryLight;
        color: $accentMain;

        &:hover {
            background-color: darken($primaryLight, 8%);
            border-color: darken($primaryLight, 8%);
            color: $accentMain;
        }
    }
}

input,
textarea,
select {
    background-color: $grey300;
    color: $accentMain;
    border: none;
    border-radius: 4px;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    display: inline-block;
    padding: 4px 8px;

    &::placeholder {
        color: $accentMain;
        opacity: 0.4 !important;
    }
}

label {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

textarea {
    resize:vertical;
    padding: 12px 16px;
}

.questionnaire {

}

.badge {
    background-color: $accentMain;
    color: $primaryLight;

    width: 64px;
    height: 72px;

    display: grid;
    place-content: center;
    border-radius: 24px;

    font-size: 18px;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}