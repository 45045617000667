@import 'assets/scss/global-styles.scss';

.edit-prospect-view {

    h1 {
        margin-top: 0;
    }
}

.prospect-view {
    background-color: $primaryLight;
    position: relative;
    padding: 64px 96px;

    @media screen and (max-width: $bp-md) {
        padding: 16px 16px 40px;
    }

    .heading {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        column-gap: 32px;

        h2 {
            margin: 0;
            width: 40%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .retour {
            cursor: pointer;
            user-select: none;
        }
    }
}

.modal {
    .form {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }
}