@import 'assets/scss/global-styles.scss';

.login-form {
  display: flex;
  flex-direction: column;
  padding: $mediumSpacing;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 512px;
  margin: 0 auto;
  margin-top: -2.5%;

  img {
    margin-bottom: 16px;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    column-gap: $smallSpacing;
    margin-bottom: $mediumSpacing;
    width: 100%;

    input {
      width: 80%;
      margin: $smallSpacing auto;
    }
  }
}