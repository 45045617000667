@import 'assets/scss/global-styles.scss';

.header {
    height: 64px;
    padding: $mediumSpacing $largeSpacing;
    background-color: $secondaryDark;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
        max-height: 50px;
        object-fit: cover;
    }

    .user-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        column-gap: 10px;
        p {
            color: $paper;
            margin: 0;
        }
        .user {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            cursor: pointer;
        }
    }
}
