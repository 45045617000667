@import 'assets/scss/global-styles.scss';

.value {
    font-weight: 700;
}

.quick-recap {
    .data {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        margin-block: 32px;
    }
}

.prospect-infos {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 584px), 1fr));
    margin-block: 32px 64px;
}

.questionary {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-bottom: 24px;

    .question-block {
        .question {
            margin-bottom: 8px;
            font-weight: 600;
        }

        .answer {
            background-color: $grey300;
            padding: 16px 24px;
            color: $secondaryMain;
            width: 80%;

            @media screen and (max-width: $bp-md) {
                width: 100%;
            }
        }
    }
}

.techincal-sheet {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 400px), 1fr));

    .inputs {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
    }

    label {
        display: flex;
        flex-direction: row;
        column-gap: 8px;

        div, span {
            font-weight: 700;
        }
    }

    .title {
        margin-block: 8px 12px;
        font-weight: 700;
        font-size: 18px;
    }
}

.ctas {
    margin-top: 32px;
}