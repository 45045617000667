@import 'assets/scss/global-styles.scss';

form, .form {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  @media screen and (max-width: $bp-md) {
    display: grid;
    grid-template-columns: 1fr;
  }

  section {
    border: 2px solid $accentMain;
    border-radius: 8px;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: fit-content;
    box-sizing: border-box;

    @media screen and (max-width: $bp-md) {
      width: 100%;
    }


    .title {
      font-size: 20px;
      display: flex;
      align-items: center;
      column-gap: 16px;

      .step {
        background-color: $accentMain;
        color: $primaryLight;
        width: 32px;
        height: 32px;
        border-radius: 100vmax;
        position: relative;

        &::before {
          position: absolute;
          top: 48%;
          left: 50%;
          transform: translate(-50%, -50%);
          counter-increment: section;
          content: counters(section, ".") " ";
        }
      }
    }

    .inputs {
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 8px;

      textarea {
        height: 100%;
      }
    }

    &.step {
      /*&-2 {
          .inputs {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 16px;
          }
      }*/

      &-3 {

        .inputs {
          display: grid;
          grid-template-columns: repeat(3, 128px);
          gap: 16px;

          @media screen and (max-width: $bp-md) {
            grid-template-columns: 1fr;
          }

          button {
            grid-column: 1/-1;
            width: 100%;
          }
        }
      }

      &-5 {
        box-sizing: border-box;

        .inputs {
          .button {
            width: auto;
            text-align: center;
          }
        }
      }

      &-6 {
        flex-grow: 1;

        .inputs {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 8px 16px;

          @media screen and (max-width: $bp-md) {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
}
.prospect-component {
  counter-reset: section;
  display: grid;
  grid-template-columns: minmax(653px, auto) max(256px);
  column-gap: 48px;

  @media screen and (max-width: $bp-md) {
    display: flex;
    flex-direction: column-reverse;
  }



  .prospect-infos {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    height: fit-content;
    position: sticky;
    top: 116px;
    margin: 0;
    background-color: $secondaryMain;
    color: $primaryLight;
    padding: 24px;
    border-radius: 8px;


    .visio {
      display: flex;
      flex-direction: column;
      row-gap: 14px;

      button, .button {
        width: 100%;
        text-align: center;
      }
    }

    & > div {
      overflow-wrap: anywhere;
    }

    @media screen and (max-width: $bp-md) {
      position: static;
    }
  }

  .questionnaire {
    margin-top: 80px;
    row-gap: 0;
    column-gap: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    h3 {
      text-align: center;
      margin: 0;
    }

    @media screen and (max-width: $bp-md) {
      row-gap: 0;
      grid-template-columns: 1fr;
    }

    h3 {
      grid-column: span 2;

      @media screen and (max-width: $bp-md) {
        grid-column: span 1;
      }
    }

    section {
      padding-inline: 0;
      border: none;
      width: 100%;
      justify-content: space-between;

      &:nth-of-type(odd):last-of-type {
        grid-column: 1 /-1;
      }

      .title {
        color: $black;
        font-weight: 600;
        font-size: 18px;
      }
    }

    .ctas {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      width: 100%;
      justify-content: center;
      grid-column: 1 / -1;

      .button, button {
        box-sizing: border-box;
        display: block;
        height: 37px;
      }
    }

    textarea {
      height: fit-content;
    }
  }

  .inputs {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 16px;
  }
}