@import 'assets/scss/global-styles.scss';

.app {
    width: 100%;
    height: 100vh;

    > div {
        margin-left: 180px;
        min-height: 100%;

        transition: margin-left 0.4s ease;

        @media screen and (max-width: $bp-md) {
            margin-left: 0;
        }
    }

    &:has(.sidebar.hide) > div {
        margin-left: calc(180px - 236px);

        @media screen and (max-width: $bp-md) {
            margin-left: 0;
        }
    }
}