@import 'assets/scss/global-styles.scss';

aside.sidebar {
    box-sizing: border-box;

    background-color: $secondaryMain;
    color: $primaryLight;

    padding: 72px 32px 32px;

    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100vh;
    width: 180px;

    position: fixed;
    left: 0;
    z-index: 99;

    transition: all 0.4s ease;

    @media screen and (max-width: $bp-md) {
        left: auto;
        right: 0;

        .collapse {
            position: absolute;
            top: 24px;
            right: 16px;
            cursor: pointer;

            @media screen and (max-width: $bp-md) {
                position: fixed;
                top: 48px;
                right: 24px;

                .bar {
                    background-color: $primaryLight !important;
                }
            }

            .icon {
                box-sizing: border-box;
                position: relative;
                height: 12px;
                width: 16px;

                @media screen and (max-width: $bp-md) {
                    width: 24px;
                    height: 16px;
                }

                .bar {
                    position: absolute;
                    display: block;
                    background-color: $primaryLight;
                    transform-origin: center;

                    height: 2px;
                    width: 100%;
                    border-radius: 100vmax;

                    top: 50%;
                    left: 50%;

                    transform: translate(-50%, -50%);
                    transition: all 0.4s ease;

                    &:nth-of-type(1),
                    &:nth-of-type(3) {
                        top: 5px;
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }

                    &:nth-of-type(2) {
                        top: 5px;
                        transform: translate(-50%, -50%) rotate(45deg);
                    }

                    &:nth-of-type(3) {
                        display: none;
                    }
                }
            }
        }
    }




    .logo {
        width: 72px;
        height: auto;
    }

    menu {
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;

        margin-top: 64px;

        li {
            padding: 8px 0;
            position: relative;
            user-select: none;
            cursor: pointer;

            a {
                text-decoration: none;
                color: inherit;
            }

            &::after {
                content: '';
                display: block;

                position: absolute;
                bottom: 0;
                height: 2px;
                width: 100%;

                background-color: $primaryLight;
                border-radius: 100vmax;

                scale: 0;

                transition: scale 0.3s ease;
            }

            &:hover,
            &.active {
                &::after {
                    scale: 1;
                }
            }
        }
    }

    button {
        margin-top: auto;
        background-color: transparent;
        border: none;
        position: relative;
        padding: 4px 0;

        &::after {
            content: '';
            display: block;

            position: absolute;
            bottom: 0;
            height: 2px;
            width: 100%;

            background-color: $primaryLight;
            border-radius: 100vmax;

            scale: 0;

            transition: scale 0.3s ease;
        }

        &:hover {
            background-color: transparent;
            color: inherit;

            &::after {
                scale: 1;
            }
        }
    }

    @media screen and (max-width: $bp-md) {
        left: -236px;

        @media screen and (max-width: $bp-md) {
            left: auto;
            right: -280px;
        }

        @media screen and (max-width: $bp-md) {
            .bar {
                background-color: $secondaryMain !important;
            }
        }

        .collapse .icon .bar {
            transform: translate(-50%, -50%);

            &:nth-of-type(1) {
                top: 0;
            }

            &:nth-of-type(2) {
                top: 100%;
            }

            &:nth-of-type(3) {
                display: block;
                top: 50%;
            }
        }
    }
}