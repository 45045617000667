@import 'assets/scss/global-styles.scss';

.quotations {
  height: 750px;
}

.head {
  h3 {
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 56px;
  }

  @media screen and (max-width: $bp-md) {
    flex-direction: column;
    align-items: start;
    row-gap: 16px;
  }
}

.dashboard {
  .head {
    position: sticky;
    top: 0;
    background-color: $primaryLight;
    z-index: 99;
    padding: 64px 0 40px;
  }
}
