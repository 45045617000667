@import 'assets/scss/global-styles.scss';

.table {
  margin-top: 40px;

  &-header,
  &-body,
  .row {
    display: grid;
    align-items: center;
    justify-items: center;
  }

  &-header {
    display: grid;
    margin-bottom: 24px;

    font-weight: 700;
    font-size: 18px;

    @media screen and (max-width: $bp-md) {
      display: none;
    }
  }

  .cell {
    width: 100%;
    text-align: center;
    overflow-wrap: break-word;

    @media screen and (max-width: $bp-md) {
      font-size: 16px;
      text-align: left;
      width: fit-content;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    @media screen and (max-width: $bp-md) {
      row-gap: 40px;
    }

    .row {
      width: 100%;
      position: relative;

      @media screen and (max-width: $bp-md) {
        display: flex;
        flex-wrap: wrap;
        row-gap: 4px;
        column-gap: 4px;
        padding-right: 30%;
        box-sizing: border-box;
        align-items: start;
      }

      .field {
        &-zipcode.format {
          background-color: $accentMain;
          color: $primaryLight;

          width: 72px;
          height: 64px;

          display: grid;
          place-content: center;
          border-radius: 24px;

          font-size: 18px;

          @media screen and (max-width: $bp-md) {
            grid-column: span 2;
            border-radius: 12px;
            display: none;
          }
        }

        &-email {
          @media screen and (max-width: $bp-md) {
            grid-column: span 2;
            grid-row: 2;
          }
        }

        &-firstname, &-lastname {
          font-weight: 800;
          font-size: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &-email {
          width: 70%;
        }

        &-zipcode:not(.format), &-created-at, &-email {
          @media screen and (max-width: $bp-md) {
            flex: 0 0 100%;
          }
        }

        &-edit, &-archive {
          svg {
            cursor: pointer;
            aspect-ratio: 1;
            height: 32px;
          }
        }

        @media screen and (max-width: $bp-md) {
          $right-space: 0px;
          $icon-spacing: 48px;

          &-edit, &-archive {
            justify-self: center;
            align-content: center;
            height: 100%;
            position: absolute;
            right: $right-space;
            top: 50%;
            transform: translateY(-50%);

            width: fit-content;
          }

          &-edit {
            right: calc(($right-space * 2) + $icon-spacing);
          }

          &-situation {
            order: 7;
            width: 100%;

            select {
              width: 80px;
            }
          }
        }
      }
    }
  }
}